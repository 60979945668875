import {
    AsyncResult,
    callActionOrExecute,
    CommerceProperty,
    DataServiceQuery,
    IContext,
    IDataServiceRequest,
    IQueryResultSettings
} from '@msdyn365-commerce/retail-proxy';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

/**
 * CustomerLiterStatistics entity interface.
 */
export interface ICustomerLiterStatistics {
    RecId?: number;
    InvoiceAccount?: string;
    Year?: number;
    InvoiceAmount?: number;
    Volume?: number;
    ItemGroupDisplayOrderName?: string;
    VolumeUnit?: string;
    ExtensionProperties?: CommerceProperty[];
}

export class CustomerLiterStatisticsExtensionClass implements ICustomerLiterStatistics {
    public RecId: number;
    public InvoiceAccount: string;
    public Year: number;
    public InvoiceAmount: number;
    public Volume: number;
    public ItemGroupDisplayOrderName: string;
    public VolumeUnit: string;
    public ExtensionProperties?: CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;
        this.InvoiceAccount = odataObject.InvoiceAccount;
        this.Year = odataObject.Year;
        this.InvoiceAmount = odataObject.InvoiceAmount ? parseFloat(odataObject.InvoiceAmount) : 0;
        this.Volume = odataObject.Volume ? parseFloat(odataObject.Volume) : 0;
        this.ItemGroupDisplayOrderName = odataObject.ItemGroupDisplayOrderName;
        this.VolumeUnit = odataObject.VolumeUnit;

        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

function getCustomerLiterStatisticsQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getCustomerLiterStatisticsInput(queryResultSettings: IQueryResultSettings): IDataServiceRequest {
    const query = getCustomerLiterStatisticsQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'GetCustomerLiterStatistics',
        true,
        CustomerLiterStatisticsExtensionClass,
        'true',
        { bypassCache: 'get', returnEntity: 'ICustomerLiterStatistics' }
    );
}

export function getCustomerLiterStatisticsAsync(context: IContext): AsyncResult<ICustomerLiterStatistics[]> {
    const request = getCustomerLiterStatisticsInput(context.queryResultSettings || {});
    return callActionOrExecute<ICustomerLiterStatistics[]>(request, context.callerContext);
}
